import React from "react";
import { Row, Button } from "react-bootstrap";

const DaysFilter = ({ title, setDays, days }) => {
  const buttons = [
    { text: "7D", value: 7 },
    { text: "1M", value: 30 },
    { text: "3M", value: 90 },
    { text: "6M", value: 180 },
    { text: "ALL", value: null },
  ];
  return (
    <Row className="days-buttons-padding">
      <div align="left" className="cashflow-title">
        <h4>{title}</h4>
      </div>
      <div align="right">
        {buttons.map((option) => (
          <Button
            key={option.text}
            className="risk-button"
            size="sm"
            variant={days === option.value ? "secondary" : "sky-blue"}
            onClick={() => setDays(option.value)}
          >
            {option.text}
          </Button>
        ))}
      </div>
    </Row>
  );
};

export default DaysFilter;
