import _ from "lodash";
import { registerABI, registerFormatter, registerContract } from "./contractRegistry.js";
import { registerAPI } from "./apiRegistry.js";

import { api, apy, currency, environment, quadrata, cashFlowCharts } from "./config";
import {
  arrayOfBNToDecimal,
  BNToDecimal,
  compareWithMaxUint,
  convertToDays,
  hoursToDays,
  toDecimal,
} from "./helpers/number_format_helper";
import {
  convertCashflow,
  convertResponse,
  setValueAsPercentageWithMax,
  makeQueryParams,
  addDaysParams,
  addOverrides,
} from "./helpers/api_calls.js";

registerABI("ERC20", require("@openzeppelin/contracts/build/contracts/IERC20Metadata.json").abi);
registerABI("EToken", require("@ensuro/core/build/contracts/EToken.sol/EToken.json").abi);
registerABI("RiskModule", require("@ensuro/core/build/contracts/RiskModule.sol/RiskModule.json").abi);
registerABI("PremiumsAccount", require("@ensuro/core/build/contracts/PremiumsAccount.sol/PremiumsAccount.json").abi);
registerABI("PolicyPool", require("@ensuro/core/build/contracts/PolicyPool.sol/PolicyPool.json").abi);
registerABI("MintableUSDC", require("./abis/MintableUSDC.json").abi);
registerABI("FaucetUSDC", require("./abis/ERC20Faucet.json").abi);
registerABI("ILPWhitelist", require("@ensuro/core/build/contracts/interfaces/ILPWhitelist.sol/ILPWhitelist.json").abi);
registerABI("QuadrataPassport", require("@quadrata/contracts/abis/QuadPassport.json"));

registerFormatter("ERC20", "balanceOf", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("ERC20", "totalSupply", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("ERC20", "allowance", _.partial(BNToDecimal, _, currency.decimals));

if (environment.testnet) {
  registerContract(currency.address, "MintableUSDC");
} else {
  registerContract(currency.address, "ERC20");
}

registerFormatter("EToken", "balanceOf", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("EToken", "totalWithdrawable", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("EToken", "totalSupply", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("EToken", "allowance", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("EToken", "scr", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("EToken", "internalLoanInterestRate", _.partial(BNToDecimal, _, 18));
registerFormatter("EToken", "maxUtilizationRate", _.partial(BNToDecimal, _, 18));
registerFormatter("EToken", "minUtilizationRate", _.partial(BNToDecimal, _, 18));
registerFormatter("EToken", "liquidityRequirement", _.partial(BNToDecimal, _, 18));
registerFormatter("EToken", "scrInterestRate", _.partial(BNToDecimal, _, 18));
registerFormatter("EToken", "tokenInterestRate", _.partial(BNToDecimal, _, 18));
registerFormatter("EToken", "assetManager", undefined);
registerFormatter("EToken", "getLoan", _.partial(BNToDecimal, _, currency.decimals));

registerFormatter("RiskModule", "params", _.partial(arrayOfBNToDecimal, _, 18));
registerFormatter("RiskModule", "maxPayoutPerPolicy", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("RiskModule", "maxDuration", _.partial(hoursToDays, _));
registerFormatter("RiskModule", "exposureLimit", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("RiskModule", "activeExposure", _.partial(BNToDecimal, _, currency.decimals));

registerFormatter("PremiumsAccount", "deficitRatio", _.partial(BNToDecimal, _, 18));
registerFormatter("PremiumsAccount", "borrowedActivePP", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("PremiumsAccount", "activePurePremiums", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("PremiumsAccount", "wonPurePremiums", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("PremiumsAccount", "purePremiums", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("PremiumsAccount", "surplus", _.partial(BNToDecimal, _, currency.decimals));
registerFormatter("PremiumsAccount", "jrLoanLimit", _.partial(compareWithMaxUint, _, currency.decimals));
registerFormatter("PremiumsAccount", "srLoanLimit", _.partial(compareWithMaxUint, _, currency.decimals));
registerFormatter("PremiumsAccount", "assetManager", undefined);
registerFormatter("PremiumsAccount", "juniorEtk", undefined);
registerFormatter("PremiumsAccount", "seniorEtk", undefined);

registerFormatter("ILPWhitelist", "acceptsDeposit(address,address,uint256)", undefined);
registerFormatter("ILPWhitelist", "acceptsWithdrawal(address,address,uint256)", undefined);

registerFormatter("QuadrataPassport", "setAttributesBulk", undefined);
registerFormatter("QuadrataPassport", "setAttributesIssuer", undefined);

/** EToken Endpoints **/
registerAPI(
  "apy",
  (address, days = apy.range) => `${api.url}/etokens/${address}/apr/?days_from=${days}`,
  (response) => Math.min(toDecimal(response.apy), toDecimal(apy.maxAPY))
);
registerAPI(
  "apyHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo, days = apy.range) =>
    `${api.url}/etokens/${address}/apr_history/${makeQueryParams(addDaysParams(daysFrom, daysTo, days))}`,
  (response) => setValueAsPercentageWithMax(response.data, "apy", 200)
);
registerAPI(
  "scrBreakdown",
  (address) => `${api.url}/etokens/${address}/scr_breakdown/`,
  (response) => convertResponse(response.data, "scr")
);
registerAPI(
  "paScrBreakdown",
  (address) => `${api.url}/etokens/${address}/pa_scr_breakdown/`,
  (response) => convertResponse(response.data, "scr")
);
registerAPI(
  "lockup",
  (address) => `${api.url}/etokens/${address}/lockup/`,
  (response) => convertResponse(response.data, "scr")
);
registerAPI(
  "etkScrHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/etokens/${address}/scr_history/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => response.data
);
registerAPI(
  "totalSupplyHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/etokens/${address}/liquidity_history/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => response.data
);
registerAPI(
  "urHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/etokens/${address}/ur_history/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => setValueAsPercentageWithMax(response.data, "ur")
);
registerAPI(
  "etkOwners",
  (address, top = 10) => `${api.url}/etokens/${address}/lps_breakdown/?top=${top}`,
  (response) => response.data
);
registerAPI(
  "compositionHistory",
  (address, period = 1) => `${api.url}/etokens/${address}/composition_history/?period=${period}`,
  (response) => response.data
);

/** Risk Module Endpoints **/
registerAPI(
  "activePolicies",
  (address) => `${api.url}/policies/?rm=${address}&status=active&limit=1`,
  (response) => response.count
);
registerAPI(
  "surplus",
  (address) =>
    `${api.url}/riskmodules/${address}/surplus/${makeQueryParams(
      { wonpremiums: true, earnings: true },
      addOverrides(address)
    )}`,
  (response) => toDecimal(response.surplus)
);
registerAPI(
  "maturedSurplus",
  (address) =>
    `${api.url}/riskmodules/${address}/matured_surplus/${makeQueryParams(
      { wonpremiums: true, earnings: true },
      addOverrides(address)
    )}`,
  (response) => toDecimal(response.matured_surplus)
);
registerAPI(
  "gwp",
  (address) => `${api.url}/riskmodules/${address}/gwp/`,
  (response) => toDecimal(response.gwp)
);
registerAPI(
  "scr",
  (address) => `${api.url}/riskmodules/${address}/scr/`,
  (response) => toDecimal(response.scr)
);
registerAPI(
  "cashflow",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/riskmodules/${address}/cashflow/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => convertCashflow(response.data)
);
registerAPI(
  "surplusHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/riskmodules/${address}/surplus_history/${makeQueryParams(
      addDaysParams(daysFrom, daysTo),
      { wonpremiums: true, earnings: true },
      addOverrides(address)
    )}`,
  (response) => response.data
);
registerAPI(
  "maturedSurplusHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/riskmodules/${address}/matured_surplus_history/${makeQueryParams(
      addDaysParams(daysFrom, daysTo),
      { wonpremiums: true, earnings: true },
      addOverrides(address)
    )}`,
  (response) => response.data
);
registerAPI(
  "activePoliciesHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/riskmodules/${address}/active_policies/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => response.data
);
registerAPI(
  "gwpHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/riskmodules/${address}/gwp_history/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => response.data
);
registerAPI(
  "scrHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/riskmodules/${address}/scr_history/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => response.data
);
registerAPI(
  "activePremiums",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/riskmodules/${address}/active_premiums/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => response.data
);
registerAPI(
  "rmByPremiumsAccount",
  (paAddress) => `${api.url}/riskmodules/?premiums_account=${paAddress}`,
  (response) => response
);
registerAPI(
  "wonPremiumsInOut",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/riskmodules/${address}/wonpremiumsinout/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => response.data
);

registerAPI(
  "avgPayoutPerPolicy",
  (address) => `${api.url}/riskmodules/${address}/avg_payout_per_policy/`,
  (response) => toDecimal(response.avg_payout_per_policy)
);

registerAPI(
  "avgPolicyDuration",
  (address) => `${api.url}/riskmodules/${address}/avg_policy_duration/`,
  (response) => convertToDays(response.avg_policy_duration)
);

/* LPs Endpoints  */
registerAPI(
  "lpsDeposits",
  (address) => `${api.url}/lps/${address}/`,
  (response) => response.net_deposits
);

registerAPI(
  "lpEvents",
  (etkAddress, order, limit = 10) =>
    `${api.url}/lpevents/?e_token=${etkAddress}` + (order ? "&o=" + order : "") + "&limit=" + limit,
  (response) => response.results
);

/* Premiums Account Endpoints */
registerAPI(
  "paGwp",
  (address) => `${api.url}/premiumsaccounts/${address}/gwp/`,
  (response) => toDecimal(response.gwp)
);
registerAPI(
  "paGwpHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/premiumsaccounts/${address}/gwp_history/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => response.data
);
registerAPI(
  "paScr",
  (address) => `${api.url}/premiumsaccounts/${address}/scr/`,
  (response) => toDecimal(response.scr)
);
registerAPI(
  "paScrHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/premiumsaccounts/${address}/scr_history/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => response.data
);
registerAPI(
  "paActivePolicies",
  (address) => `${api.url}/premiumsaccounts/${address}/active_policies/`,
  (response) => toDecimal(response.active_policies)
);
registerAPI(
  "paActivePoliciesHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/premiumsaccounts/${address}/active_policies_history/${makeQueryParams(
      addDaysParams(daysFrom, daysTo)
    )}`,
  (response) => response.data
);
registerAPI(
  "paSurplus",
  (address) =>
    `${api.url}/premiumsaccounts/${address}/surplus/${makeQueryParams(
      { wonpremiums: true, earnings: true },
      addOverrides(address)
    )}`,
  (response) => toDecimal(response.surplus)
);
registerAPI(
  "paSurplusHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/premiumsaccounts/${address}/surplus_history/${makeQueryParams(
      addDaysParams(daysFrom, daysTo),
      { wonpremiums: true, earnings: true },
      addOverrides(address)
    )}`,
  (response) => response.data
);
registerAPI(
  "paMaturedSurplus",
  (address) =>
    `${api.url}/premiumsaccounts/${address}/matured_surplus/${makeQueryParams(
      { wonpremiums: true, earnings: true },
      addOverrides(address)
    )}`,
  (response) => toDecimal(response.matured_surplus)
);
registerAPI(
  "paMaturedSurplusHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/premiumsaccounts/${address}/matured_surplus_history/${makeQueryParams(
      addDaysParams(daysFrom, daysTo),
      { wonpremiums: true, earnings: true },
      addOverrides(address)
    )}`,
  (response) => response.data
);
registerAPI(
  "paActivePremiums",
  (address) => `${api.url}/premiumsaccounts/${address}/active_premiums/`,
  (response) => toDecimal(response.active_premiums)
);
registerAPI(
  "paActivePremiumsHistory",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/premiumsaccounts/${address}/active_premiums_history/${makeQueryParams(
      addDaysParams(daysFrom, daysTo)
    )}`,
  (response) => response.data
);
registerAPI(
  "paCashflow",
  (paAddress, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/premiumsaccounts/${paAddress}/cashflow/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => convertCashflow(response.data)
);
registerAPI(
  "rmBreakdown",
  (paAddress) => `${api.url}/premiumsaccounts/${paAddress}/rm_breakdown/`,
  (response) => convertResponse(response.data, "active_pure_premiums")
);
registerAPI(
  "paWonPremiumsInOut",
  (address, daysFrom = cashFlowCharts.daysFrom, daysTo) =>
    `${api.url}/premiumsaccounts/${address}/wonpremiumsinout/${makeQueryParams(addDaysParams(daysFrom, daysTo))}`,
  (response) => response.data
);

/* Wallet Endpoints */
registerAPI(
  "checkUser",
  (address) => `${api.url}/wallet/${address}/`,
  (response) => response
);
registerAPI(
  "refreshWallet",
  (address) => `${api.url}/wallet/${address}/refresh/`,
  (response) => response,
  "POST"
);

registerAPI("storeKYC", () => `${api.url}/wallet/`, undefined, "POST");

/* Quadrata Endpoints */
registerAPI(
  "accessToken",
  () => `${api.url}/wallet/access_token/`,
  (response) => {
    return response.access_token;
  },
  "POST"
);

registerAPI(
  "quadAttributes",
  (userAddress, chainId, attributes, privacyScopes) =>
    `${quadrata.apiUrl}/v2/attributes/onboard_status?wallet=${userAddress}&chainId=${chainId}&attributes=${attributes}&privacyScopes=${privacyScopes}`,
  (response) => {
    return response.data;
  }
);
