import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "semantic-ui-react";
/* Components */
import BreakdownSection from "../CommonForBoth/BreakdownSection";
import DescriptionCard from "../CommonForBoth/DescriptionCard";
import CashflowCharts from "../CommonForBoth/CashflowCharts";
import MoreInfoDocs from "../CommonForBoth/MoreInfoDocs";
import MiniWidget from "../CommonForBoth/MiniWidget";
import DaysFilter from "../CommonForBoth/DaysFilter";
import LineMinichart from "../Charts/LineMinichart";
import BarMinichart from "../Charts/BarMinichart";
import RisksTabs from "./Tabs/RisksTabs";
import PAParams from "./PAParams";
/* Selectors */
import { selectEthCallMultiple } from "@ensuro/ethereum-store/src/store/ethereum/selectors";
import { selectAPICallMultiple } from "@ensuro/api-calls-store/src/store/api/selectors";
import { selectCurrent } from "../../store/premiumsAccounts/selectors";
import { selectSetting } from "../../store/settings/selectors";
import { selectRiskNames } from "../../store/risks/selectors";
/* Store */
import * as rmActionTypes from "../../store/risks/actionTypes";
import * as etkActionTypes from "../../store/eTokens/actionTypes";
/* Helpers */
import { setPercentages } from "../../helpers/charts_helper";
import { addRemoveSub } from "../../helpers/store_helper";
//i18n
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { minicharts, cashFlowCharts } from "../../config";
import { isEmpty } from "lodash";
import colors from "../../colors";

const makeEthCalls = function (paAddress) {
  return [
    { address: paAddress, abi: "PremiumsAccount", method: "jrLoanLimit", args: [] },
    { address: paAddress, abi: "PremiumsAccount", method: "srLoanLimit", args: [] },
    { address: paAddress, abi: "PremiumsAccount", method: "assetManager", args: [] },
    { address: paAddress, abi: "PremiumsAccount", method: "deficitRatio", args: [] },
    { address: paAddress, abi: "PremiumsAccount", method: "activePurePremiums", args: [] },
    { address: paAddress, abi: "PremiumsAccount", method: "juniorEtk", args: [] },
    { address: paAddress, abi: "PremiumsAccount", method: "seniorEtk", args: [] },
    { address: paAddress, abi: "PremiumsAccount", method: "surplus", args: [] },
  ];
};

const makeEthEtkLoansCalls = function (paAddress, etkAddress) {
  return [{ address: etkAddress, abi: "EToken", method: "getLoan", args: [paAddress] }];
};

const makeAPICalls = (paAddress) => {
  return [
    { apiName: "paGwp", args: [paAddress] },
    { apiName: "paScr", args: [paAddress] },
    { apiName: "paMaturedSurplus", args: [paAddress] },
    { apiName: "paActivePolicies", args: [paAddress] },
    { apiName: "rmBreakdown", args: [paAddress] },
    { apiName: "rmByPremiumsAccount", args: [paAddress] },
  ];
};

const minichartAPICalls = (paAddress) => {
  return [
    { apiName: "paGwpHistory", args: [paAddress, minicharts.daysFrom] },
    { apiName: "paScrHistory", args: [paAddress, minicharts.daysFrom] },
    { apiName: "paMaturedSurplusHistory", args: [paAddress, minicharts.daysFrom] },
    { apiName: "paActivePoliciesHistory", args: [paAddress, minicharts.daysFrom] },
  ];
};

const chartCalls = (address, daysFrom) => {
  return [
    { apiName: "paActivePremiumsHistory", args: [address, daysFrom] },
    { apiName: "paMaturedSurplusHistory", args: [address, daysFrom] },
    { apiName: "paCashflow", args: [address, daysFrom] },
    { apiName: "paWonPremiumsInOut", args: [address, daysFrom] },
  ];
};

const PADetail = ({
  t,
  pa,
  paAddress,
  customData,
  links,
  gwp,
  gwpHistory,
  scr,
  scrHistory,
  maturedSurplus,
  maturedSurplusHistory,
  activePolicies,
  policiesHistory,
  jrLoanLimit,
  srLoanLimit,
  jrLoan,
  srLoan,
  assetManager,
  deficitRatio,
  activePurePremiums,
  rmBreakdownByPercentage,
  juniorEtk,
  seniorEtk,
  rms,
  mode,
  surplus,
}) => {
  let dispatch = useDispatch();
  const [tab, setTab] = useState();
  const [days, setDays] = useState(cashFlowCharts.daysFrom);

  const handleSelectTab = (rm) => {
    setTab(rm);
  };

  useEffect(() => {
    const ethCalls = makeEthCalls(paAddress);
    if (juniorEtk?.value !== undefined) ethCalls.push(...makeEthEtkLoansCalls(paAddress, juniorEtk.value));
    if (seniorEtk?.value !== undefined) ethCalls.push(...makeEthEtkLoansCalls(paAddress, seniorEtk.value));
    return addRemoveSub(dispatch, "paDetail", makeAPICalls(paAddress).concat(minichartAPICalls(paAddress)), ethCalls);
  }, [dispatch, paAddress, juniorEtk.value, seniorEtk.value]);

  useEffect(() => {
    dispatch({ type: rmActionTypes.GET_RISKS_MODULES });
    dispatch({ type: etkActionTypes.GET_ETOKENS });
  }, [dispatch]);

  const am = assetManager && assetManager.value && customData && customData[assetManager.value];

  return (
    <Container className="no-margin">
      <Row>
        <MiniWidget
          data={{
            title: t("Active Policies"),
            label: t("Active Policies"),
            value: activePolicies,
            options: { notation: "standard" },
          }}
          Component={LineMinichart}
          graph={policiesHistory}
          dataKey="active_policies"
          color={colors.primaryLight}
          address={paAddress}
          apiCall={"paActivePoliciesHistory"}
        />
        <MiniWidget
          data={{ title: t("GWP"), value: gwp, label: "GWP [$]", options: { notation: "compact" }, prefix: "$" }}
          Component={BarMinichart}
          graph={gwpHistory}
          dataKey="gwp"
          color={colors.skyBlue}
          address={paAddress}
          apiCall={"paGwpHistory"}
        />
        <MiniWidget
          data={{ title: t("SCR"), value: scr, label: "SCR [$]", options: { notation: "compact" }, prefix: "$" }}
          Component={LineMinichart}
          graph={scrHistory}
          dataKey="scr"
          color={colors.pink}
          address={paAddress}
          apiCall={"paScrHistory"}
        />
        <MiniWidget
          data={{
            title: t("Surplus/Deficit"),
            label: "Surplus [$]",
            value: maturedSurplus,
            options: { notation: "compact" },
            prefix: "$",
          }}
          Component={LineMinichart}
          graph={maturedSurplusHistory}
          dataKey="matured_surplus"
          color={colors.green}
          address={paAddress}
          apiCall={"paMaturedSurplusHistory"}
        />
      </Row>
      {pa.description !== "" && links && links.length > 0 && (
        <Row>
          <Col md={8}>
            <DescriptionCard description={pa.description} />
          </Col>
          {links && links.length > 0 && (
            <Col md={4}>
              <MoreInfoDocs docs={links} />
            </Col>
          )}
        </Row>
      )}
      <Row>
        <Col md={8}>
          <Card fluid>
            <br />
            <DaysFilter days={days} title={t("Cashflow")} setDays={setDays} />
            <br />
            <CashflowCharts address={paAddress} daysFrom={days} apiCalls={chartCalls} />
            <BreakdownSection
              data={rmBreakdownByPercentage}
              title={t("Risks Breakdown")}
              selectTab={handleSelectTab}
              samePage={true}
            />
          </Card>
        </Col>
        <Col md={4}>
          <PAParams
            address={paAddress}
            jrLoanLimit={jrLoanLimit}
            srLoanLimit={srLoanLimit}
            jrLoan={jrLoan}
            srLoan={srLoan}
            assetManager={{ am: assetManager, text: am }}
            deficitRatio={deficitRatio}
            activePurePremiums={activePurePremiums}
            surplus={surplus}
            jrEtkAddress={juniorEtk}
            srEtkAddress={seniorEtk}
          />
        </Col>
      </Row>
      <Row>
        <RisksTabs risks={rms} mode={mode} activeTab={tab} selectTab={handleSelectTab} />
      </Row>
    </Container>
  );
};

PADetail.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const pa = selectCurrent(state.PAReducer);
  const riskModules = selectRiskNames(state.RisksReducer);
  const mode = selectSetting(state.SettingsReducer, "VIEW_MODE");
  const paAddress = pa.address;
  const customData = pa.custom_data;
  const links = pa.links;

  const [jrLoanLimit, srLoanLimit, assetManager, deficitRatio, activePurePremiums, juniorEtk, seniorEtk, surplus] =
    selectEthCallMultiple(state.EthereumReducer, makeEthCalls(paAddress));

  const [jrLoan] =
    juniorEtk?.value !== undefined
      ? selectEthCallMultiple(state.EthereumReducer, makeEthEtkLoansCalls(paAddress, juniorEtk.value))
      : [{}];
  const [srLoan] =
    seniorEtk?.value !== undefined
      ? selectEthCallMultiple(state.EthereumReducer, makeEthEtkLoansCalls(paAddress, seniorEtk.value))
      : [{}];

  const [gwp, scr, maturedSurplus, activePolicies, rmBreakdown, rms] = selectAPICallMultiple(
    state.APIReducer,
    makeAPICalls(paAddress)
  );
  const [gwpHistory, scrHistory, maturedSurplusHistory, policiesHistory] = selectAPICallMultiple(
    state.APIReducer,
    minichartAPICalls(paAddress)
  );

  const rmBreakdownByPercentage =
    rmBreakdown.state === "LOADED" && !isEmpty(riskModules)
      ? setPercentages(rmBreakdown.value, riskModules, "active_pure_premiums")
      : [];

  return {
    pa,
    paAddress,
    customData,
    links,
    gwp,
    gwpHistory,
    scr,
    scrHistory,
    maturedSurplus,
    maturedSurplusHistory,
    activePolicies,
    policiesHistory,
    jrLoanLimit,
    srLoanLimit,
    jrLoan,
    srLoan,
    assetManager,
    deficitRatio,
    activePurePremiums,
    rmBreakdownByPercentage,
    juniorEtk,
    seniorEtk,
    rms,
    mode,
    surplus,
  };
};

export default connect(mapStateToProps)(withTranslation()(PADetail));
