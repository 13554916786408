import React, { useState } from "react";
import PropTypes from "prop-types";
import DaysFilter from "../CommonForBoth/DaysFilter";
import ModalChart from "../Charts/ModalChart";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { cashFlowCharts } from "../../config";
import "./style.scss";

const ExpandChartModal = ({ t, show, onHide, title, apiCall, address, dataKey, type, color, yLabel }) => {
  const [days, setDays] = useState(cashFlowCharts.daysFrom);

  return (
    <Modal show={show} centered onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DaysFilter setDays={setDays} days={days} />
        <br />
        <ModalChart
          address={address}
          daysFrom={days}
          apiCall={apiCall}
          dataKey={dataKey}
          color={color}
          type={type}
          yLabel={yLabel}
        />
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

ExpandChartModal.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(ExpandChartModal);
