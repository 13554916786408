import React, { useEffect } from "react";
/* Components */
import { Container } from "react-bootstrap";
import Loader from "react-js-loader";
import ETokens from "./ETokens";
/* Selectors */
import { selectSetting } from "../../../store/settings/selectors";
import { connect, useDispatch } from "react-redux";
import * as actionTypes from "../../../store/eTokens/actionTypes";

const ETokensTable = ({ etokens, state, mode }) => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: actionTypes.SET_SELECTED_ETOKEN, address: "" });
  }, [dispatch]);

  return (
    <Container className="no-margin">
      {state.ETokensReducer.loadingState !== "LOADED" || !etokens.length ? (
        <div className="loader">
          <Loader type="spinner-default" height={50} width={100} timeout={3000} />
        </div>
      ) : (
        <ETokens eTokens={etokens} state={state} mode={mode} />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  const etokens = state.ETokensReducer.eTokens;
  const mode = selectSetting(state.SettingsReducer, "VIEW_MODE");

  return { etokens, state, mode };
};

export default connect(mapStateToProps)(ETokensTable);
